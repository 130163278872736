import CustomInput from "../Custom/CustomInput";
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Shipping = () => {
    const [cities, setCities] = useState([]);
 const [country, setCountry]=useState([]);
      const [loading, setLoading] = useState(true); // Add a loading state
      const [formData, setFormData] = useState({
        Firstname: "",
        Lastname: "",
        country: "",
        city: "",
        Address1: "",
        Mobile:"",
      });


      const resetForm=()=>{
        setFormData({ 
        Firstname: "",
        Lastname: "",
        country: "",
        city: "",
        Address1: "",
        Mobile:"",})
      }

      useEffect(() => {
        resetForm();
      }, []);

      const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type === "checkbox") {
          setFormData({
            ...formData,
            [id]: checked,
          });
        } else {
          setFormData({
            ...formData,
            [id]: value,
          });
        }
      };
    
  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/Cities/get`);
        if (response.data && Array.isArray(response.data)) {
          setCities(response.data);
        } else {
          setCities([]);
        }
      } catch (error) {
        console.error('Error fetching cities:', error);
        setCities([]);
      } finally {
        setLoading(false); // Set loading to false once the API call is complete
      }
    };

    fetchCities();
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/Countires/get`);
        if (response.data && Array.isArray(response.data)) {
          setCountry(response.data);
        } else {
            setCountry([]);
        }
      } catch (error) {
        console.error('Error fetching countries:', error);
        setCountry([]);
      } finally {
        setLoading(false); // Set loading to false once the API call is complete
      }
    };

    fetchCountries();
  }, []);
    return (
    <div className="Input-div">
        
            <div className="Bar"> <p className="Bar-p">Title</p> <select className="Bar-input" name="title">
  <option value="Mr.">Mr.</option>
  <option value="Mrs.">Mrs.</option>
</select> </div>
            <div className="Bar"> <p className="Bar-p">First Name</p> <CustomInput  className={"Bar-input"} id={"Firstname"}/></div>
            <div className="Bar"> <p className="Bar-p">Last Name</p> <CustomInput  className={"Bar-input"} id={"Lastname"}/></div>
            <div className="Bar">
      <p className="Bar-p">Country</p>
      <select className="Bar-input" name="country">
        {loading ? (
          <option value="">Loading...</option>
        ) : country.length > 0 ? (
            country.map((country) => (
            <option key={country.id} value={country.name}>
              {country.name}
            </option>
          ))
        ) : (
          <option value="">No countries available</option>
        )}
      </select>
    </div>
            <div className="Bar">
      <p className="Bar-p">City</p>
      <select className="Bar-input" name="city">
        {loading ? (
          <option value="">Loading...</option>
        ) : cities.length > 0 ? (
          cities.map((city) => (
            <option key={city.id} value={city.name}>
              {city.name}
            </option>
          ))
        ) : (
          <option value="">No cities available</option>
        )}
      </select>
    </div>
            <div className="Bar"> <p className="Bar-p">Address </p> <CustomInput  className={"Bar-input"} id={"Address1"}/></div>
            <div className="Bar"> <p className="Bar-p">Postcode / Zip</p> <CustomInput  className={"Bar-input"} id={"Zip"}/></div>
            
            <div className="Bar"> <p className="Bar-p">Mobile</p> <CustomInput  className={"Bar-input"} id={"Mobile"}/></div>
            <div className="Bar"><p className="Bar-p"></p> 
                </div>
      </div>

);
        };
        export default Shipping;