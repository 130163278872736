import React from "react";
import item from "../Layout/apple-watch.png"
import Cart from "../Layout/cart3.svg"
import { useNavigate } from "react-router-dom";
import { useState } from "react";
const Card =()=>{
    const [cartItems, setCartItems] = useState([]);
    const handleAddToCart = () => {
        const newItem = {
          id: 1,
          name: "Rolex",
          description: "Black bird Lebanon 40mm Stainless Steel and Leather Watch",
          price: 3750.0,
          color: "black",
          images: [
            "../../Layout/watch/1.png",
            "../../Layout/watch/2.png",
            "../../Layout/watch/3.png",
            "../../Layout/watch/4.png",
          ],
          quantity: 1, // Set an initial quantity
          totalPrice: 3750.0, // Set an initial total price based on the quantity and price
        };
      
        setCartItems((prevCartItems) => {
          const updatedCartItems = [...prevCartItems, newItem];
          localStorage.setItem("cart", JSON.stringify(updatedCartItems));
          return updatedCartItems;
        });
      };
      
    const navigate=useNavigate();
    return(< >
    <div className="card-background">
       <div className="discount-div"><p className="discount-p">-82%</p></div> 
        <img src={item} className="card-item" onClick={()=>navigate("/Product")}/>
       <div className="card-desc">
        <div className="card-desc-p1">
        <p className="card-desc-name">Apple Watch 8</p>
        <p className="card-desc-price">$ 99 </p>
        </div>
        <div className="card-buy">
            <img src={Cart} className="card-cart" onClick={handleAddToCart}/>
        </div>
        </div>
    </div>
    </>)
};
export default Card;