import CustomInput from "../Custom/CustomInput";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CustomButton from "../Custom/CustomButton";

const ShippingModal = ({ formData, handleInputChange, customOnChange, onClose, isOpen, localStorageData, totalPrice, shipping, taxes }) => {
    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saveIsLoading, setSaveIsLoading] = useState(false);

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/Cities/get`);
                setCities(response.data || []);
            } catch (error) {
                console.error('Error fetching cities:', error);
                setCities([]);
            } finally {
                setLoading(false);
            }
        };

        fetchCities();
    }, []);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/Countires/get`);
                setCountries(response.data || []);
            } catch (error) {
                console.error('Error fetching countries:', error);
                setCountries([]);
            } finally {
                setLoading(false);
            }
        };

        fetchCountries();
    }, []);

    const save = async () => {
        if (localStorageData.length) {
            setSaveIsLoading(true);

            const requestData = {

                final_total: totalPrice,
                firstname: formData.firstname,
                lastname: formData.lastname,
                title: formData.title,
                mobile: formData.mobile,
                country: formData.country,
                city: formData.city,
                address1: formData.address1,
                details: localStorageData.map((product) => ({
                    id: product.id,
                    quantity: product.quantity,
                    price: product.price,
                    final_price: product.totalPrice,
                    company_id: product.company_id,

                }))
            };

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/Orders/add`, requestData);
                console.log("Data sent successfully", response.data);
                setSaveIsLoading(false);
                onClose();
                // Additional actions after successful data submission
            } catch (error) {
                console.error("Failed to send data", error);
                setSaveIsLoading(false);
                // Handle errors or display a message to the user
            }
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal1">
            <div className="modal1-content">
                <span className="close3" onClick={onClose}>&times;</span>
                <h2>Shipping Information</h2>
                <div className="Input-div">
                    <div className="Bar">
                        <p className="Bar-p">Title</p>
                        <select className="Bar-input" name="title" value={formData.title} onChange={customOnChange}>
                            <option value=''></option>
                            <option value="Mr.">Mr.</option>
                            <option value="Mrs.">Mrs.</option>
                        </select>
                    </div>
                    <div className="Bar">
                        <p className="Bar-p">First Name</p>
                        <CustomInput className={"Bar-input"} id={"firstname"} value={formData.firstname} onChange={handleInputChange} />
                    </div>
                    <div className="Bar">
                        <p className="Bar-p">Last Name</p>
                        <CustomInput className={"Bar-input"} id={"lastname"} value={formData.lastname} onChange={handleInputChange} />
                    </div>
                    <div className="Bar">
                        <p className="Bar-p">Country</p>
                        <select className="Bar-input" name="country" value={formData.country} onChange={customOnChange}>
                            <option value=''></option>
                            {loading ? (
                                <option value="">Loading...</option>
                            ) : countries.length > 0 ? (
                                countries.map((country) => (
                                    <option key={country.id} value={country.id}>
                                        {country.name}
                                    </option>
                                ))
                            ) : (
                                <option value="">No countries available</option>
                            )}
                        </select>
                    </div>
                    <div className="Bar">
                        <p className="Bar-p">City</p>
                        <select className="Bar-input" name="city" value={formData.city} onChange={customOnChange}>
                            <option value=''></option>
                            {loading ? (
                                <option value="">Loading...</option>
                            ) : cities.length > 0 ? (
                                cities.map((city) => (
                                    <option key={city.id} value={city.id}>
                                        {city.name}
                                    </option>
                                ))
                            ) : (
                                <option value="">No cities available</option>
                            )}
                        </select>
                    </div>
                    <div className="Bar">
                        <p className="Bar-p">Address</p>
                        <CustomInput className={"Bar-input"} id={"address1"} value={formData.address1} onChange={handleInputChange} />
                    </div>
                    <div className="Bar">
                        <p className="Bar-p">Postcode / Zip</p>
                        <CustomInput className={"Bar-input"} id={"zip"} value={formData.zip} onChange={handleInputChange} />
                    </div>
                    <div className="Bar">
                        <p className="Bar-p">Mobile</p>
                        <CustomInput className={"Bar-input"} id={"mobile"} value={formData.mobile} onChange={handleInputChange} />
                    </div>
                </div>
                <CustomButton
                    className={"ShoppingCart-btn-checkout"}
                    name="Save"
                    onClick={save}
                    isLoading={saveIsLoading}
                />
            </div>
        </div>
    );
};

export default ShippingModal;
