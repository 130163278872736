import React from "react";
import Logo from "../Layout/walmart.svg"
import Search from "../Layout/search.svg"
import Cart from "../Layout/cart.svg"
import Profile from "../Layout/person.svg"
import Heart from "../Layout/heart.svg"
import BasketPopup from "./BasketPopup"
import WhishList from "./WhishList"
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CustomButton from "./Custom/CustomButton"
const NavBar = () => {
    const navigate = useNavigate();
    const [color,setColor]=useState(false);
    const [color2,setColor2]=useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [popupWhish, setPopupWhish] = useState(false);
    const localStorageData = JSON.parse(localStorage.getItem("cart")) || [];
    const hasItemsInCart = localStorageData.length > 0;

    const toggle = () => {
        setColor(!color);
        navigate("/");
    };

    const openPopup = () => {
        setPopupOpen(true);
      };
      const openwhish = () => {
        setPopupWhish(true);
      };
    
      const closewhish = () => {
        setPopupWhish(false);
      };
      const closePopup = () => {
        setPopupOpen(false);
      };


      
  return (
    <div className="navbar">
      <button className="nav-logo-btn" onClick={()=>navigate("/")}>
        Otloob
      </button>
    
      <div className="icons">
        <button onClick={openwhish} className="Heart-btn"><img className="Nav-icon" src={Heart} alt=""/></button>
                    <WhishList isOpen={popupWhish} onClose={closewhish} />
                    <button className="Profile-btn"  onClick={()=>navigate("/Login")}><img className="Nav-icon"  src={Profile} alt=""/></button>
                    <button className="Cart-btn"onClick={openPopup} ><img className="Nav-icon" src={Cart}  alt=""/>
                    {hasItemsInCart && <div className="dot-indicator"></div>}
</button>
                    <BasketPopup isOpen={popupOpen} onClose={closePopup} />
                
      </div>

      <div className="Lower-Nav">
                    <CustomButton className={"Home-btn"} onClick={()=>navigate("/Category")} name={'Shop'}/>
                    {/* <CustomButton className={"Home-btn"} onClick={()=>navigate("/")} name={'Baby Clothes and Accessories'}/>
                    <CustomButton className={"Home-btn"} onClick={()=>navigate("/")} name={'Kids Clothes'}/>
                    <CustomButton className={"Home-btn"} onClick={()=>navigate("/")} name={'Toys'}/>
                    <CustomButton className={"Home-btn"} onClick={()=>navigate("/")} name={'Perfumes and Beauty'}/>
                    <CustomButton className={"Home-btn"} onClick={()=>navigate("/")} name={'Home Appliances'}/> */}
                    </div>
    </div>
  );
};

export default NavBar;
