import React from "react";
import NavBarMarketPlace from "../Comp/NavBar";
import ProcessLine from "../Comp/ProcessLine";
import Footer2 from "../Comp/Footer";
import SignIn from "../Comp/Login/SignIn";
import Register from "../Comp/Login/Register";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);

  return (
    <>
      <div className="Nav">
        <NavBarMarketPlace />
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-12" style={{ marginTop: "13%" }}>
            <div className="Login-div">
              {" "}
              <div className="Title-page">
                <p>Sign In</p>
              </div>
            </div>
            <div className="Login-div">
              <ProcessLine currentStep={currentStep} />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div>
              {" "}
              <SignIn />
            </div>
          </div>

          <div className="col-lg-6 mid-border">
            <div>
              <Register />
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "5%" }}>
        <Footer2 />
      </div>
    </>
  );
};
export default Login;
