import React from 'react'
import NavBar from '../Comp/NavBar'
import Footer from '../Comp/Footer'
import CustomInput from '../Comp/Custom/CustomInput'
import CustomButton from '../Comp/Custom/CustomButton'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

function RestPassword() {
  const navigate=useNavigate();
  return (
    <div>
      <div className="Nav">
        <NavBar />
      </div>

<div className='Central-Div'>

  <div className='Reset-Pass'>
  <div>
    <p className='Reset-Pass-Title'>Password Reset</p>
    </div>
    <div className='Reset-Pass-Email'>
  <p className='Reset-Pass-Email-p1'>PLEASE ENTER THE EMAIL ADDRESS </p>
  <p className='Reset-Pass-Email-p2'>TO CONTINUE</p>

  <CustomInput className={'Reset-Pass-Email-Input'} placeholder={"Email"}/>

  <CustomButton className={"Reset-Pass-btn1"} name={"Continue"} onClick={()=>navigate('/ResetPassword2')}/>


  <Link to={'/Login'}  className='Reset-Pass-Return'>Back To Sign In</Link>

  </div>



  </div>

</div>

      <Footer/>

</div>  
  )
}

export default RestPassword
