import React from "react";

const DeliveryMethod=()=>{
    return(<>
    <div>
        <p className="DeliveryMethod-BigTitle">2. DELIVERY METHOD</p>
        <div className="DeliveryMethod-Background">
        <div className="DeliveryMethod-Title">
        <p className="DeliveryMethod-Title1">EXPRESS DELIVERY (£Free)</p>
        <p className="DeliveryMethod-Title1">PACKAGING OPTIONS</p>
      </div>

      <div className="DeliveryMethod-Detail">
      <div className="DeliveryMethod-Detail1">
<p className="DeliveryMethod-Detail1-p">Delivered in 3-4 business days</p>
      </div>
      <div className="DeliveryMethod-Detail2">
        <p className="DeliveryMethod-Detail2-p">Caibre Premium Packaging</p>
       
        
        </div>
      </div>

      <div className="DeliveryMethod-Div">
    <div className="DeliveryMethod-Div1">
        <p>CHANGE SHIPPING ADDRESS</p>
    </div>
    
</div>
        </div>
      </div>
    </>)
}; export default DeliveryMethod;