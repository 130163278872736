import React from 'react'
import {FormControl,InputLabel,Select,MenuItem} from '@mui/material'
function ProductItemHeader() {
  return (
    <div>
        <div className='d-flex  justify-content-between'>
          <div>
          <p className='ProductItem-header-title'>Store </p>
          </div>
          
          
        </div>
      
    </div>
  )
}

export default ProductItemHeader
