import React from "react";
import ShoppingProductCounter from "../ShoppingProductCounter";
import Watch1 from "../../Layout/watch/1.png";

const ShoppingProduct = ({ onUpdateTotal }) => {
  const localStorageData = JSON.parse(localStorage.getItem("cart")) || [];

  const handleQuantityChange = (index, newQuantity) => {
    const updatedData = [...localStorageData];

    if (updatedData[index]) {
      updatedData[index] = {
        ...updatedData[index],
        quantity: newQuantity,
        totalPrice: updatedData[index].price * newQuantity,
      };
    }

    localStorage.setItem("cart", JSON.stringify(updatedData));

    if (typeof onUpdateTotal === "function") {
      onUpdateTotal(updatedData);
    }
  };

  return (
    <>
      {localStorageData && localStorageData.length > 0 ? (
        <div className="ShoppingProduct-container">
          {localStorageData.map((product, index) => (
            <div
              key={product.id}
              style={{ display: "flex", marginBottom: "3%" }}
              className="ShoppingProduct-item"
            >
              <img
               src={product.images?.length > 0 ? `${process.env.REACT_APP_API_BASE_URL_FILE}/images/${product.images[0].image}` : 'default-image-source'}
               className="ShoppingProduct-img"
                alt={`Product ${index + 1}`}
              />
              <p className="ShoppingProduct-descp">{product.description}</p>
    
              <div className="ShoppingProduct-counter-div">
                <ShoppingProductCounter
                  initialQuantity={product.quantity || 1}
                  onQuantityChange={(newQuantity) =>
                    handleQuantityChange(index, newQuantity)
                  }
                />
              </div>
              <p className="ShoppingProduct-price">{`$${
                product.totalPrice || 0
              }`}</p>
            </div>
          ))}
        </div>
      ) : (
        <p style={{ display: "flex", justifyContent: "center" }}>
          No product data available.
        </p>
      )}
    </>
  );
};

export default ShoppingProduct;
