// src/AccordionItem.js
import React, { useState } from 'react';
import up from '../../Layout/up.svg'
import down from '../../Layout/down.svg'
import CustomInput from '../Custom/CustomInput';

// src/AccordionItem.js

const AccordionFilter = ({ title, item, checklistItems, type, min, max, value, step, onChange,divID,handleChangeMultiFilter,handleChangeFilter }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="cat-accordion-item">
      <div className="cat-accordion-div1" onClick={toggleAccordion}>

        <p className='cat-accordion-title'>
          {title}
          {type === 'range' && <span className="range-value">: {value}</span>}
        </p>
        <img
          src={isExpanded ? up : down}
          className="cat-arrow-icon"
        />

      </div>
      {isExpanded && (
        <div className="cat-accordion-content">
          {type === 'checkbox' && (
            <ul>
              {checklistItems.map((item, index) => (
                <li key={index}>
                  <label className='cat-accordion-label'>
                    <CustomInput
                      className={'cat-accordion-checkbox'}
                      type={'checkbox'}
                      onChange={()=>handleChangeMultiFilter(divID,item.id)}
                      id={divID}
                      value={value}
                    />{' '}
                    <p className='cat-accordion-p'>{item.name}</p>
                  </label>
                </li>
              ))}
            </ul>
          )}
          {type === 'range' && (
            <div>
              <label className='cat-accordion-label2'>
                <CustomInput
                  className={'cat-accordion-custom-range '}
                  id={'price'}
                  type={'range'}
                  min={min}
                  max={max}
                  value={value}
                  // value={props.filterForm.price}
                  onChange={onChange}
                  step={step}
                />
                <p className='cat-accordion-p'>{item.name}</p>
              </label>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AccordionFilter;
