import React, { useEffect, useState } from "react";
import CustomInput from "../Custom/CustomInput";
import { useNavigate } from "react-router-dom";
import CustomButton from "../Custom/CustomButton";
import axios from "axios";
import { useDispatch } from "react-redux";
import allActions from "../../Action/allActions";
import cookie from "js-cookie";

const SignIn = () => {
  const [saveIsLoading, setSaveIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    resetForm();
  }, []);

  const resetForm = () => {
    setFormData({
      email: "",
      password: "",
    });
  };

  const handleInputChange = (event) => {
    const { id, value, checked, type } = event.target;
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [id]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [id]: value,
      });
    }
  };

  const OnClickSignIn = () => {
    setSaveIsLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}/auth/login`,
      data: {
        email: formData.email.toLowerCase().trim(),
        password: formData.password,
      },
    })
      .then((res) => {
        cookie.set("token", res.data.access_token);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data.access_token}`;
        dispatch(allActions.userActions.setUser(res.data.user));
        setSaveIsLoading(false);
        navigate(`/ShoppingCart`);
      })
      .catch((error) => {
        console.log("Error:", error);
        if (error?.response?.status === 401) {
          console.log("Wrong Credentials");
        } else {
          console.log("Unhandled error:", error);
          // Handle other errors as needed
        }
        setSaveIsLoading(false);
      })
      .finally(() => {
        setSaveIsLoading(false);
      });
  };

  const handleSubmit = (e) => {
    if (e.key === 'Enter') {
      OnClickSignIn();
    }
  }
  return (
    <div>
      <div className="SignIn-div1">
        <p className="SignIn-Title">Existing customer</p>
      </div>
      <p className="SignIn-descp">
        Make purchases faster using previously saved details. Track previous
        orders and easily request returns from the website.
      </p>
      <CustomInput
        placeholder={"Email"}
        onChange={handleInputChange}
        id={"email"}
        value={formData.email}
        onKeyDown={handleSubmit}
      />
      <CustomInput
        placeholder={"Password"}
        onChange={handleInputChange}
        id={"password"}
        value={formData.password}
        type={"password"}
        onKeyDown={handleSubmit}
      />
      <p className="SignIn-Forgot">Forgot Password?</p>
      <CustomButton
        onClick={OnClickSignIn}
        className={"SignIn-btn"}
        name={"Sign In"}
        isLoading={saveIsLoading}
      />
    </div>
  );
};

export default SignIn;
