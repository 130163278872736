import React from "react";
import NavBarMarketPlace from "../Comp/NavBar";
import { useNavigate } from "react-router-dom";
import { useState ,useEffect} from "react";
import ProcessLine from "../Comp/ProcessLine";
import OrderSummary from "../Comp/Billing/OrderSummary";
import ReviewOrderAddress from "../Comp/Billing/ReviewOrderAddress";
import DeliveryMethod from "../Comp/Billing/DeliveryMethod";
import PaymentDetail from "../Comp/Billing/PaymentDetail";
import Footer from "../Comp/Footer"
import CustomButton from "../Comp/Custom/CustomButton";

const Billing = () => {
    const [currentStep, setCurrentStep] = useState(3);
    const [orderItems,setOrderItems]=useState([])
    const navigate = useNavigate();

    useEffect(() => {
        const storedData = localStorage.getItem("order");
        if (storedData) {
          setOrderItems(JSON.parse(storedData));
        }
      }, []);

  /*  const [formdata,setFormdata]=useState([]);
    const savedetails=()=>{
        let item=[];
        item.push({
            serial:0,
            item_id:1,
            quantity:1,
            cost:11,
            price:1,

        })
        setFormdata(item);

    }*/
    const handleNextStep = () => {
        setCurrentStep((prevStep) => Math.min(prevStep + 1, 4));
        navigate("/Confirmation");

    };
    return (<>
        <div className="Nav">
            <NavBarMarketPlace />
        </div>

        <div className="container">
            <div className="row">
                <div className="col-lg-12" style={{ marginTop: "13%" }}>
                    <div className="Login-div"> <div className="Title-page"><p>Billing</p></div></div>
                    <div className="Login-div"><ProcessLine currentStep={currentStep} /></div>
                </div>
            </div>

        </div>
        <div className="container">
            <div className="row">
                <div className="col-lg-12" style={{ marginLeft: "5%" }}>
                    <OrderSummary />
                </div>
            </div>

      {  /*    <div className="row">
                <div className="col-lg-12" style={{
                    marginLeft: "5%",
                    marginTop: "5%"
                }}>
                    <ReviewOrderAddress />
                </div>
            </div>*/}

            <div className="row">
                <div className="col-lg-12" style={{
                    marginLeft: "5%",
                    marginTop: "5%",
                    display:"none",
                }}>
                    <DeliveryMethod />
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12" style={{
                    marginLeft: "5%",
                    marginTop: "5%"
                }}>
                    <PaymentDetail />
                </div>
            </div>


            <div className="row">
                <div className="col-lg-12" style={{
                    marginLeft: "5%",
                    marginTop: "5%"
                }}>
                    <div className="Billing-btn-div">
                        <CustomButton className={"Billing-btn"} onClick={handleNextStep} disabled={currentStep === 4} name={"COMPLETE PURCHASE"} />
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>)
}; export default Billing;