import React, { useEffect, useState } from "react";
import CustomInput from "../Custom/CustomInput";
import axios from "axios";
import { useDispatch } from "react-redux";
import allActions from "../../Action/allActions";
import cookie from "js-cookie";
import CustomButton from "../Custom/CustomButton";
import  { toast } from 'react-toastify';


const Register = () => {
  const dispatch = useDispatch();
  const [saveIsLoading, setSaveIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  const resetForm = () => {
    setFormData({
      name: "",
      username: "",
      email: "",
      password: "",
      password_confirmation: "",
    });
  };

  useEffect(() => {
    resetForm();
  }, []);

  const handleInputChange = (event) => {
    const { id, value, checked, type } = event.target;
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [id]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [id]: value,
      });
    }
  };

  const saveForm = () => {
    console.log(formData);
    // Add any validation logic if needed
  };

  const OnClickRegister = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    setSaveIsLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}/auth/signup`,
      data: {
        name: formData.name,
        username: formData.username,
        email: formData.email,
        password: formData.password,
        password_confirmation: formData.password_confirmation,
      },
    })
      .then((res) => {
        cookie.set("token", res.data.access_token);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data.access_token}`;
        toast.success('Success');

        dispatch(allActions.userActions.setUser(res.data.user));
        // You can redirect the user or perform other actions upon successful signup
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log(error, "Wrong Credentials");
          toast.error('Error');

        } else {
          console.log(error);
          toast.error('Error');

        }
        setSaveIsLoading(false);
      });
  };

  return (
    <>
      <div>
        <div className="Register-div1">
          <p className="Register-Title">New Customer</p>
        </div>
        <p className="Register-descp">
          You’ll need an account to purchase watches, track and review orders,
          and manage your personal details.
        </p>

        <CustomInput
          placeholder={"Full Name"}
          onChange={handleInputChange}
          id={"name"}
        />
        <CustomInput
          placeholder={"User-Name"}
          onChange={handleInputChange}
          id={"username"}
        />
        <CustomInput
          placeholder={"Email"}
          onChange={handleInputChange}
          id={"email"}
        />
        <CustomInput
          placeholder={"Password"}
          onChange={handleInputChange}
          id={"password"}
          type={"password"}
        />
        <CustomInput
          placeholder={"Retype-Password"}
          onChange={handleInputChange}
          id={"password_confirmation"}
          type={"password"}
        />
        <div className="Register-div2">
          <CustomButton
            className={"Register-btn"}
            onClick={OnClickRegister}
            name={"Register"}
          />
        </div>
        <p className="Register-Policy">
          By registering, you agree to our Terms and Conditions and Privacy
          Policy
        </p>
      </div>
    </>
  );
};

export default Register;
