import React, { useState, useEffect } from "react";

const QuantityCounter = ({ initialQuantity, onQuantityChange }) => {
  const [quantity, setQuantity] = useState(initialQuantity);

  const incNum = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    if (typeof onQuantityChange === "function") {
      onQuantityChange(newQuantity);
    }
  };

  const decNum = () => {
    const newQuantity = quantity - 1 >= 1 ? quantity - 1 : 1;
    setQuantity(newQuantity);
    if (typeof onQuantityChange === "function") {
      onQuantityChange(newQuantity);
    }
  };

  return (
    <div>
      <div className="Panier-counter">
        <button className="Panier-decbtn" onClick={decNum}>
          -
        </button>
        <input
          readOnly={true}
          type="number"
          className="Panier-productinput"
          value={quantity}
        />
        <button className="Panier-incbtn" onClick={incNum}>
          +
        </button>
      </div>
    </div>
  );
};

export default QuantityCounter;
