import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import NavBarMarketPlace from "../Comp/NavBar";
import Footer from "../Comp/Footer";
import ShoppingProduct from "../Comp/ShoppingCart/ShoppingProduct";
import wallet from "../Layout/wallet.svg";
import CustomButton from "../Comp/Custom/CustomButton";
import { useNavigate } from "react-router-dom";
import ShippingModal from "../Comp/ShoppingCart/ShippingModal";

const ShoppingCart = () => {
  const [orderID, setOrderID] = useState(null);
  const [localStorageData, setLocalStorageData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.currentUser);
  const [formData, setFormData] = useState({
    title: "",
    firstname: "",
    lastname: "",
    country: "",
    city: "",
    address1: "",
    zip: "",
    mobile: "",
  });

  useEffect(() => {
    const storedData = localStorage.getItem("cart");
    if (storedData) {
      setLocalStorageData(JSON.parse(storedData));
    }

    const storedOrderID = localStorage.getItem("orderID");
    if (storedOrderID) {
      setOrderID(storedOrderID);
    } else {
      const generatedOrderID = generateOrderID();
      setOrderID(generatedOrderID);
      localStorage.setItem("orderID", generatedOrderID);
    }
  }, []);

  useEffect(() => {
    if (localStorageData.length) {
      const subtotal = localStorageData.reduce((acc, product) => acc + product.totalPrice, 0);
      const calculatedTaxes = subtotal * 0.1; // 10% taxes
      const calculatedShipping = subtotal > 1000 ? 0 : 20; // Free shipping for orders over $1000, else $20

      setTaxes(calculatedTaxes);
      setShipping(calculatedShipping);
      setTotalPrice(subtotal + calculatedTaxes + calculatedShipping);
    }
  }, [localStorageData]);

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };
  const customOnChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  }

  const generateOrderID = () => {
    const timestamp = new Date().getTime();
    const randomNum = Math.floor(Math.random() * 10000);
    return `ORDER-${timestamp}-${randomNum}`;
  };

  const handleClearLocalStorage = () => {
    localStorage.removeItem("cart");
    setLocalStorageData([]);
    setTotalPrice(0);
    const newOrderID = generateOrderID();
    setOrderID(newOrderID);
    localStorage.setItem("orderID", newOrderID);
  };

  const buyNow = () => {
    if (currentUser && currentUser?.user?.id > 0 && currentUser?.loggedIn === true) {
      setIsModalOpen(true);
    } else {
      navigate('/Login');
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <NavBarMarketPlace />
      <div className="container">
        <div className="row">
          <div className="col-lg-12" style={{ marginTop: "13%" }}>
            <div className="Login-div">
              <div className="Title-page">
                <p style={{ marginLeft: "-4%" }}>Shopping Cart</p>
                {orderID && <p>Order ID: {orderID}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="Shop-Title-div">
              <p className="Shop-Title">Item</p>
              <p className="Shop-Title">Description</p>
              <p className="Shop-Title">Quantity</p>
              <p className="Shop-Title2">Unit Price</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ShoppingCart-Product">
              {localStorageData.map((product, index) => (
                <ShoppingProduct key={index} product={product} />
              ))}

              <div className="ShoppingCart-btn">
                <CustomButton
                  className={"ShoppingCart-btn1"}
                  name={"Move to wish list"}
                />
                <CustomButton
                  className={"ShoppingCart-btn2"}
                  name={"Delete"}
                  onClick={handleClearLocalStorage}
                />
              </div>

              <div className="ShoppingProduct-Line">
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ShoppingCart-Facture">
              <div className="ShoppingCart-Facture-Detail">
                <p className="ShoppingCart-Facture-Detail-p">SubTotal</p>
                <p className="ShoppingCart-Facture-Detail-p">${totalPrice - shipping - taxes}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ShoppingCart-Facture">
              <div className="ShoppingCart-Facture-Detail">
                <p className="ShoppingCart-Facture-Detail-p">Shipping</p>
                <p className="ShoppingCart-Facture-Detail-p">${shipping}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ShoppingCart-Facture">
              <div className="ShoppingCart-Facture-Detail">
                <p className="ShoppingCart-Facture-Detail-p">Taxes</p>
                <p className="ShoppingCart-Facture-Detail-p">${taxes}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="ShoppingCart-Facture">
              <div className="ShoppingCart-Facture-Detail">
                <p className="ShoppingCart-Facture-Detail-p">Total</p>
                <p className="ShoppingCart-Facture-Detail-p2">${totalPrice}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="ShoppingCart-btn-div">
              <div className="ShoppingCart-btn-div2">
                <div className="ShoppingCart-btn-img-div">
                  <img src={wallet} className="ShoppingCart-btn-img" alt="wallet" />
                </div>

                <CustomButton
                  className={"ShoppingCart-btn-checkout"}
                  name="Buy Now"
                  onClick={buyNow}
                />
              </div>
            </div>
            <div className="ShoppingProduct-Line">
              <p></p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="ShoppingCart-help">
              <p className="ShoppingCart-help-p1">NEED HELP?</p>
              <p className="ShoppingCart-help-p2"> CALL US: +961 71 032 030 |</p>
              <p className="ShoppingCart-help-p2"> EMAIL CUSTOMER CARE | </p>
              <p className="ShoppingCart-help-p2">SHIPPING INFORMATION |</p>
              <p className="ShoppingCart-help-p2"> RETURNS & EXCHANGES</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <ShippingModal
        formData={formData}
        handleInputChange={handleInputChange}
        customOnChange={customOnChange}
        onClose={handleModalClose}
        isOpen={isModalOpen}
        localStorageData={localStorageData}
        totalPrice={totalPrice}
        shipping={shipping}
        taxes={taxes}
      />
    </div>
  );
};

export default ShoppingCart;
