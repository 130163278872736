import React from "react";
import Product from"../Comp/WhishListItem"
import Close from "../Layout/exit.svg"
import CustomButton from "./Custom/CustomButton";



const WhishList = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="PopUp">
    <div className="PopUp-Content">
        <button className="PopUp-Close-Button" onClick={onClose}>
        <img src={Close} className="PopUp-Close"/>
       
        </button>
        <p className="PopUp-Title">WhishList</p>
        <p className="PopUp-Sub-Title">Items</p>
        <div  className="PopUp-Product-List">
            <Product/>
         
           
        </div>
       
   


      <div className="PopUp-Footer" style={{display:"flex",justifyContent:"center",
    }}>
        
      
      <div >
      <CustomButton className={"PopUp-Proceed2"} name={"PROCEED TO CART"}/>
      </div>
      

    </div>
    </div>
  
    </div>
  );
};

export default WhishList;
