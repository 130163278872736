import React from "react";
import NavBarMarketPlace from "../Comp/NavBar";
import Inputs from "../Comp/Shipping/ShippingInput"
import ProcessLine from "../Comp/ProcessLine";
import MapContainer from "../Comp/Shipping/MapContainer";
import wallet from "../Layout/wallet.svg"
import Footer from "../Comp/Footer";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CustomButton from "../Comp/Custom/CustomButton";

const Shipping = () => {
  const [currentStep, setCurrentStep] = useState(2);
const navigate =useNavigate();
  const handleNextStep = () => {
      setCurrentStep((prevStep) => Math.min(prevStep + 1, 4));
      navigate("/Billing");

  };
    
    return (
      
      <div>
        <div className="Nav">
        <NavBarMarketPlace />
        </div>
        
        <div className="container">
        <div className="row">
          <div className="col-lg-12" style={{marginTop:"13%"}}>
          <div className="Login-div"> <div className="Title-page"><p>Shipping</p></div></div> 
       <div className="Login-div"><ProcessLine currentStep={currentStep} /></div>   
          </div>
        </div>
      
      </div>
        
        <div >
        <div className="container" >
          <div className="row">
            <div className="col-lg-6">
          <Inputs/>
            </div>

            <div className="col-lg-6">
              <div style={{marginTop:"4%"}}>
                <div className="shipping-box">
                  <p className="shipping-box-p1">SHIPPING RESTRICTIONS AND DUTIES MAY APPLY</p>
                 <div style={{padding:"4%"}}>
                  <p className="shipping-box-p2">NEED HELP? </p>
                  <p className="shipping-box-p2">CALL US: +961 71 032 030</p> 
                  <p className="shipping-box-p2">EMAIL CUSTOMER CARE | SHIPPING INFORMATION</p>
                    <p className="shipping-box-p2"> RETURNS & EXCHANGES</p>
                    </div>
                </div>
                
              </div>
             
              <div className="proceed-div">
        <div className="proceed-img-div"> <img src={wallet} className="proceed-img"/></div>
         
          <CustomButton  className={"proceed-btn"} onClick={handleNextStep} disabled={currentStep === 4} name={"Proceed to checkout"}/>
        
      
      </div>
              </div>
          </div>
        </div>
        </div>
       <Footer/>
        </div>
          );
        };
        export default Shipping;