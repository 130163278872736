import React, { useEffect } from 'react';
import AccordionFilter from "./AccordionFilter";
import { useState } from 'react';
import axios from 'axios';

function ProductListSideBar(props) {
  const [sliderValue, setSliderValue] = useState(50);
  const [typesLits, setTypesList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);

  const handleSliderChange = (event) => {
    setSliderValue(event.target.value);
  };

  useEffect(() => {
    const getTypes = () => {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_BASE_URL}/Public/getTypes`,
      })
        .then((response) => {
          const data = response.data;
          setTypesList(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (typesLits.length == 0) {
      getTypes();
    }
    const getCategories = () => {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_BASE_URL}/Public/getCategories`,
      })
        .then((response) => {
          const data = response.data;
          setCategoriesList(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (categoriesList.length == 0) {
      getCategories();
    }
  }, []);

  const accordionItems = [


    {
      title: 'Product Type',
      type: 'checkbox',
      // checklistItems: ['Task 1', 'Task 2', 'Task 3', 'Task 3', 'Task 3', 'Task 3'],
      checklistItems: typesLits,
      id: 'type_id'
    },
    {
      title: 'Category',
      type: 'checkbox',
      // checklistItems: ['Task 1', 'Task 2', 'Task 3', 'Task 3', 'Task 3', 'Task 3'],
      checklistItems: categoriesList,
      id: 'category_id'
    },
    // {
    //   title: 'Price',
    //   type: 'range',
    //   min: "0",
    //   max: "5000",
    //   step: "50",
    // },
    // {
    //   title: 'Size',
    //   type: 'checkbox',
    //   checklistItems: ['Task 1', 'Task 2', 'Task 3', 'Task 3', 'Task 3', 'Task 3'],
    // },
    // {
    //   title: 'Color',
    //   type: 'checkbox',
    //   checklistItems: ['Task 1', 'Task 2', 'Task 3', 'Task 3', 'Task 3', 'Task 3'],
    // },
  ];
  return (
    <div>
      {accordionItems.map((accordion, index) => (
        <AccordionFilter
          key={index}
          title={accordion.title}
          checklistItems={accordion.checklistItems || []}
          type={accordion.type}
          min={accordion.min}
          max={accordion.max}
          step={accordion.step}
          divID={accordion.id}
          // value={accordion.type === 'range' ? sliderValue : undefined}
          value={props.filterForm.type_id}
          // onChange={accordion.type === 'range' ? handleSliderChange : props.handleChangeFilter}
          handleChangeMultiFilter={props.handleChangeMultiFilter}
          handleChangeFilter={props.handleChangeFilter}
        />
      ))}
    </div>
  );
}

export default ProductListSideBar;
