import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Accordion from "./AccordionItem";
import Star from "../../Layout/star.svg";
import cart from "../../Layout/cart.svg";
import Facebook from "../../Layout/facebook.svg";
import Twitter from "../../Layout/twitter.svg";
import Mail from "../../Layout/envelope.svg";
import Pinterest from "../../Layout/pinterest.svg";

const Product = () => {
  const { id } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [wishItems, setWishItems] = useState([]);
  const [selectedDetails, setSelectedDetails] = useState([]); // Add this state
  const [formData, setFormData] = useState({
    id: -1,
    name: "",
    images: [],
    description: "",
    barcode: "",
    type_id: "",
    category_id: "",
    details: [],
    quantity: "",
    price: "",
    cost: "",
  });

  useEffect(() => {
    getData();
  }, [id]);

  const getData = () => {
    if (id) {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_BASE_URL}/Public/getItemById-${id}`,
      })
        .then((response) => {
          const data = response.data;

          if (!data) {
            console.error("Invalid data format received from the API");
            return;
          }

          setFormData((prevFormData) => ({
            ...prevFormData,
            id: data.id || -1,
            name: data.name || "",
            images: data.images || [],
            description: data.description || "",
            barcode: data.barcode || "",
            type_id: data.type_id || "",
            category_id: data.category_id || "",
            details: data.details || [],
            quantity: data.quantity || "",
            price: data.price || "",
            cost: data.cost || "",
          }));

          // Set the first image as the default selected image
          if (data.images && data.images.length > 0) {
            setSelectedImage(
              `${process.env.REACT_APP_API_BASE_URL_FILE}/images/${data.images[0].image}`
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // Handle the case where no ID is provided, set default data or perform other logic
      setFormData({
        id: -1,
        name: "Default Product",
        images: [],
        description: "Default product description",
        barcode: "",
        type_id: "",
        category_id: "",
        details: [],
        quantity: "",
        price: "",
        cost: "",
      });
    }
  };

  const handleAddToCart = () => {
    const newItem = {
      id: formData.id,
      name: formData.name,
      description: formData.description,
      price: parseFloat(formData.price) || 0,
      color: formData.color,
      images: formData.images || [],
      quantity: 1,
      totalPrice: parseFloat(formData.price) || 0,
      selectedDetails, // Add selected details to the cart item
    };

    setCartItems((prevCartItems) => {
      const updatedCartItems = [...prevCartItems, newItem];
      localStorage.setItem("cart", JSON.stringify(updatedCartItems));
      return updatedCartItems;
    });
  };

  const handleAddToWish = () => {
    const newItem = {
      id: formData.id,
      name: formData.name,
      description: formData.description,
      price: parseFloat(formData.price) || 0,
      color: formData.color,
      images: formData.images || [],
    };

    setWishItems((prevWishItems) => {
      const updatedWishItems = [...prevWishItems, newItem];
      localStorage.setItem("Wishcart", JSON.stringify(updatedWishItems));
      return updatedWishItems;
    });
  };

  const handleSmallImageClick = (imageSrc) => {
    console.log("Clicked image source:", imageSrc);
    setSelectedImage(imageSrc);
  };

  const handleDetailsChange = (detailsArray) => {
    setSelectedDetails(detailsArray); // Ensure this is correctly updating the state
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="ProductDetail-Div1">
              {selectedImage && (
                <img
                  src={selectedImage}
                  className="ProductDetail-bigImg"
                  alt="Selected Watch"
                  style={{
                    width: "93%",
                    height: "500px",
                    objectFit: "fill",
                  }}
                />
              )}

              <div className="ProductDetail-SmallImg-div">
                {formData.images.map((el, index) => (
                  <img
                    src={`${process.env.REACT_APP_API_BASE_URL_FILE}/images/${el.image}`}
                    key={index}
                    className="ProductDetail-Small-img"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "fill",
                      cursor: "pointer",
                    }}
                    alt={`Small Watch ${index + 1}`}
                    onClick={() =>
                      handleSmallImageClick(
                        `${process.env.REACT_APP_API_BASE_URL_FILE}/images/${el.image}`
                      )
                    }
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="Product-Info">
              <div className="Product-Info2">
                <p className="Product-name">{formData.name}</p>

                <p className="Product-description"> $ {formData.price}</p>
              </div>
              <Accordion
                description={formData.description}
                details={formData.details}
                onDetailsChange={handleDetailsChange} // Pass the handle function to Accordion
              />
              <div className="ProductDetail-div5">
                <div className="ProductDetail-btn-div">
                  <div className="ProductDetail-img-div">
                    <img
                      src={cart}
                      className="ProductDetail-btn-img"
                      alt="Cart Icon"
                    />
                  </div>

                  <button
                    className="ProductDetail-btn"
                    onClick={handleAddToCart}
                  >
                    ADD TO CART
                  </button>
                </div>

                <div className="ProductDetail-btn-div">
                  <div className="ProductDetail-img-div">
                    <img
                      src={Star}
                      className="ProductDetail-btn-img"
                      alt="Star Icon"
                    />
                  </div>

                  <button
                    className="ProductDetail-btn2"
                    onClick={handleAddToWish}
                  >
                    ADD TO WISH LIST
                  </button>
                </div>
              </div>
              <div className="ProductDetail-social">
                <img
                  src={Mail}
                  style={{ marginLeft: "10%" }}
                  className="ProductDetail-social-img"
                  alt="Mail Icon"
                />
                <img
                  src={Twitter}
                  className="ProductDetail-social-img"
                  alt="Twitter Icon"
                />
                <img
                  src={Facebook}
                  className="ProductDetail-social-img"
                  alt="Facebook Icon"
                />
                <img
                  src={Pinterest}
                  className="ProductDetail-social-img"
                  alt="Pinterest Icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
