import React, { useState, useEffect } from "react";
import axios from "axios";
import Shoe1 from "../../Layout/shoe1.png";
import CustomButton from "../Custom/CustomButton";
import { useNavigate } from "react-router-dom";
const NewCollection = () => {
  const [itemsWithNumber2, setItemsWithNumber2] = useState([]);
  const [itemsWithNumber3, setItemsWithNumber3] = useState([]);
  const [itemsWithNumber4, setItemsWithNumber4] = useState([]);
  const [itemsWithNumber5, setItemsWithNumber5] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/Public/getItemsPromotion`);
        const data = response.data;

        const itemsWithNum2 = data.filter((item) => item.number === 2);
        const itemsWithNum3 = data.filter((item) => item.number === 3);
        const itemsWithNum4 = data.filter((item) => item.number === 4);
        const itemsWithNum5 = data.filter((item) => item.number === 5);

        setItemsWithNumber2(itemsWithNum2);
        setItemsWithNumber3(itemsWithNum3);
        setItemsWithNumber4(itemsWithNum4);
        setItemsWithNumber5(itemsWithNum5);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div style={{ marginTop: "3%" }} className="mobile-title">
          <p className="Coll-p2">NEW COLLECTION</p>
        </div>
        <div className="col-lg-6 mobile-margin">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-6 Coll-sm-background-mobile">
                <div className="Coll-sm-background ">
                  <img
                    src={
                      itemsWithNumber2[0]
                        ? `${process.env.REACT_APP_API_BASE_URL_FILE}/images/${itemsWithNumber2[0].default_image}`
                        : Shoe1
                    }
                    className="Coll-sm-img"
                    alt="Item 1"
                    onClick={()=>navigate(`/Product/${itemsWithNumber2[0].id}`)} 

                  />
                  <p className="Coll-sm-img-title">
                    {itemsWithNumber2[0] ? itemsWithNumber2[0].item_name : ""}
                  </p>
                  <p className="Coll-sm-img-price">${itemsWithNumber2[0] ? itemsWithNumber2[0].item_price : ""}</p>
                </div>

                <div className="Coll-sm-background">
                  <img
                    src={
                      itemsWithNumber3[0]
                        ? `${process.env.REACT_APP_API_BASE_URL_FILE}/images/${itemsWithNumber3[0].default_image}`
                        : Shoe1
                    }
                    className="Coll-sm-img"
                    alt="Item 2"
                    onClick={()=>navigate(`/Product/${itemsWithNumber3[0].id}`)} 

                  />
                  <p className="Coll-sm-img-title">
                    {itemsWithNumber3[0] ? itemsWithNumber3[0].item_name : ""}
                  </p>
                  <p className="Coll-sm-img-price">$ {itemsWithNumber3[0] ? itemsWithNumber3[0].item_price : ""}</p>
                </div>
              </div>

              <div className="col-lg-6 col-sm-6 Coll-sm-background-mobile">
                <div className="Coll-sm-background">
                  <img
                    src={
                      itemsWithNumber4[0]
                        ? `${process.env.REACT_APP_API_BASE_URL_FILE}/images/${itemsWithNumber4[0].default_image}`
                        : Shoe1
                    }
                    className="Coll-sm-img"
                    alt="Item 3"
                    onClick={()=>navigate(`/Product/${itemsWithNumber4[0].id}`)} 

                  />
                  <p className="Coll-sm-img-title">
                    {itemsWithNumber4[0] ? itemsWithNumber4[0].item_name : ""}
                  </p>
                  <p className="Coll-sm-img-price">$ {itemsWithNumber4[0] ? itemsWithNumber4[0].item_price : ""}</p>
                </div>

                <div className="Coll-sm-background">
                  <img
                    src={
                      itemsWithNumber5[0]
                        ? `${process.env.REACT_APP_API_BASE_URL_FILE}/images/${itemsWithNumber5[0].default_image}`
                        : Shoe1
                    }
                    className="Coll-sm-img"
                    alt="Item 4"
                    onClick={()=>navigate(`/Product/${itemsWithNumber5[0].id}`)} 

                  />
                  <p className="Coll-sm-img-title">
                    {itemsWithNumber5[0] ? itemsWithNumber5[0].item_name : ""}
                  </p>
                  <p className="Coll-sm-img-price">$ {itemsWithNumber5[0] ? itemsWithNumber5[0].item_price : ""}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="Coll-bg-background">
            <p className="Coll-bg-Sale-btn">Sale -50%</p>
            <p className="Coll-bg-Price-btn">$999</p>
            <img src={Shoe1} className="Coll-bg-img" alt="Sale Item" />
            <div>
              <div>
                <p className="Coll-bg-img-title">Sale Item</p>
                <p className="Coll-bg-img-descp">Description here</p>
              </div>
              <CustomButton className={"Coll-bg-buy-btn"} name={"Buy Now"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCollection;
