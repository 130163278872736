import React, { useEffect, useState } from "react";
import Product from "../Comp/PopupItem";
import Close from "../Layout/exit.svg";
import CustomButton from "./Custom/CustomButton";
import { useNavigate } from "react-router-dom";

const BasketPopup = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    updateTotalPrice();
  }, []);

  const updateTotalPrice = () => {
    const storedProducts = JSON.parse(localStorage.getItem("cart")) || [];
    const newTotalPrice = storedProducts.reduce(
      (total, product) => total + (product.totalPrice || 0),
      0
    );
    setTotalPrice(newTotalPrice);
  };

  const handleUpdateTotal = (updatedProducts) => {
    updateTotalPrice();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="PopUp">
      <div className="PopUp-Content">
        <button className="PopUp-Close-Button" onClick={onClose}>
          <img src={Close} className="PopUp-Close" alt="Close" />
        </button>
        <div>
          <p className="PopUp-Title">Your Cart</p>
          <p className="PopUp-Sub-Title">Items</p>
        </div>
        <div className="PopUp-Product-List">
          <Product onUpdateTotal={handleUpdateTotal} />
        </div>

        <div
          className="PopUp-Footer"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ display: "flex" }}>
            <p className="PopUp-Total">TOTAL</p>
            <p className="PopUp-Total-Price">
              ${totalPrice} <span style={{ marginLeft: "7%" }}>TTC</span>
            </p>
          </div>

          <div>
            <CustomButton
              className={"PopUp-Proceed"}
              name={"PROCEED TO CART"}
              onClick={() => navigate("/ShoppingCart")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasketPopup;
