// src/components/MapContainer.js
import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const mapStyles = {
  width: "280%",
  marginLeft: "-49%",
  height: "194%",
  marginTop: "-35%"

};

class MapContainer extends Component {
  render() {
    return (
      <div className="col-lg-6">
        <div style={{ position: "relative", padding: "100%" }}>
      <Map
        google={this.props.google}
        zoom={14}
        style={mapStyles}
        initialCenter={{
          lat: 37.7749, // Latitude of your location
          lng: -122.4194, // Longitude of your location
        }}
      >
        <Marker position={{ lat: 37.7749, lng: -122.4194 }} />
      </Map>
      </div>
      </div> );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAAVq5RKJH7H6IpQa427pnZAxzLHdUMA0U", // Replace with your actual API key
})(MapContainer);
