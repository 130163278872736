import React from 'react';
const Process=({ currentStep })=> {
  const steps = [
    { title: 'Sign In', description: 'Just do it' },
    { title: 'Shipping', description: 'Yesterday you said tomorrow' },
    { title: 'Billing', description: 'Just do it' },
    { title: 'Confirmation', description: 'Make your dreams come true' },
];

return (
    <div className="progress-line">
        {steps.map((step, index) => (
            <div
                key={index}
                className={`circle ${currentStep >= index + 1 ? 'completed' : ''}`}
            >
                <span className="circle-title">{step.title}</span>
            </div>
        ))}
    </div>
);
}
export default Process;
