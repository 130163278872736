import React, { useState } from "react";

function AccordionItem({ title, content, isOpen, toggleAccordion }) {
  return (
    <div
      className={`ProductDetail-Accordion-border ${isOpen ? "open" : ""}`}
    >
      <p className="ProductDetail-Accordion-title" onClick={toggleAccordion}>
        {title}
        <span className="toggle-icon">{isOpen ? "-" : "+"}</span>
      </p>
      {isOpen && (
        <div className={`product-accordion-content ${isOpen ? "open" : ""}`}>
          {content}
        </div>
      )}
    </div>
  );
}

function Accordion({ description, details = {}, onDetailsChange }) {
  const [isOpenDescription, setIsOpenDescription] = useState(false);
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState({});

  const toggleDescription = () => setIsOpenDescription(!isOpenDescription);
  const toggleDetails = () => setIsOpenDetails(!isOpenDetails);

  const handleAttributeSelection = (attribute, value) => {
    const updatedAttributes = {
      ...selectedAttributes,
      [attribute]: value,
    };
    setSelectedAttributes(updatedAttributes);
    onDetailsChange(Object.values(updatedAttributes)); // This line calls the passed function correctly
  };

  return (
    <div className="ProductDetail-Accordion-div">
      <AccordionItem
        title="Description"
        content={<p className="ProductDetail-Accordion-p">{description}</p>}
        isOpen={isOpenDescription}
        toggleAccordion={toggleDescription}
      />

      <AccordionItem
        title="Details"
        isOpen={isOpenDetails}
        toggleAccordion={toggleDetails}
        content={
          <div>
            {Object.keys(details).map((key) => (
              <div key={key} className="detail-section" style={{ marginBottom: "16px" }}>
                <p
                  className="ProductDetail-Accordion-subtitle"
                  style={{ fontWeight: "bold", marginBottom: "8px" }}
                >
                  {key}
                </p>
                <div style={{ display:"inline-flex"}}>
                  {details[key].map((item, index) => (
                    <div key={index} className="d-flex" style={{ marginBottom: "8px", marginRight:"10%"}}>
                      <input
                        type="radio"
                        name={item.title}
                        value={item.value}
                        onChange={() => handleAttributeSelection(item.title, item.value)}
                        style={{ marginRight: "5px" }}
                      />
                      <p style={{ marginTop: "0", fontSize: "14px" }}>{item.value}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        }
      />
    </div>
  );
}

export default Accordion;
