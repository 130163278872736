import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";

const CustomButton = props => {

    return (
        <>
            <button
                id={props.id}
                className={props.className}
                onClick={props.onClick}
                disabled={props.isLoading ? true : false}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                {
                    props.isLoading ?
                        <CircularProgress size={14} style={{ color: 'white' }} />
                        :
                        <>
                            {props.img}
                            {props.name}
                        </>
                }
            </button>

        </>
    )
};
export default CustomButton;