import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ProductItemHeader from './ProductItemHeader';
import Item from './Item';
import {ReactPaginate} from 'react-paginate';

function ProductItem() {
  const { typeId } = useParams();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/Public/getItems/${typeId}`);
        setItems(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchItems();
  }, [typeId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handlePage=(data)=>{
console.log(data.selected);
  }

  return (
    <div>
      <ProductItemHeader />
      <div style={{ marginTop: "5%" }}>
        {items.map(item => (
          <Item key={item.id} data={item} />
        ))}
      </div>

     <div> 
</div>
    </div>
    
  );
}

export default ProductItem;
