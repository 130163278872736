import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import heart from "../../Layout/noun_Heart_2102871 (1).svg";

function Item({ data }) {
  const [isActive, setIsActive] = useState(false);
  const handleClick = () => {
    setIsActive(!isActive);
  };
  const navigate = useNavigate();

  return (
    <div style={{ marginTop: "3%" }}>
      <div className="Product-Item-Background">
        <img
          src={`${process.env.REACT_APP_API_BASE_URL_FILE}/images/${data.default_image}`}
          style={{
            width: "200px",
            height: "200px",
            objectFit: "fill",
          }}
          alt="Product Image"
          className="Product-Item-img"
          onClick={() => navigate(`/Product/${data.id}`)}
        />
        <div
          className={`${
            isActive
              ? "Product-Item-img2-background-change"
              : "Product-Item-img2-background"
          }`}
          onClick={handleClick}
        >
          <img src={heart} className="Product-Item-img2" alt="Heart Icon" />
        </div>
        <p className="Product-Item-Name">{data.name}</p>
        <p className="Product-Item-Price">${data.price}</p>
      </div>
    </div>
  );
}

export default Item;
