import React, { useEffect, useState } from "react";

const CustomInput = props => {

    return (
        <>
            <input 
                id={props.id}
                type={props.type ? props.type : 'text'} 
                placeholder={props.placeholder} 
                className={props.className ? props.className : "Register-Email"}
                onChange={props.onChange} 
                onKeyDown={props.onKeyDown}
                />
        </>
    )
}; 
export default CustomInput;