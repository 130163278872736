import React from "react";

const PaymentDetail = () => {
  return (
    <>
      <p className="PaymentDetail-BigTitle">3. PAYMENT DETAILS</p>
      <div className="PaymentDetail-Background">
        <div className="PaymentDetail-Title">
          <p>PAYMENT METHOD: CASH ON DELIVERY</p>
        </div>
      </div>
    </>
  );
};
export default PaymentDetail;
