import React from "react";
import NavBarMarketPlace from "../Comp/NavBar";
import Gradient from "../Layout/Gradientimg.jpg";
import Footer from "../Comp/Footer";

import Card1 from "../Comp/Card";
import Card2 from "../Comp/Card";
import Card3 from "../Comp/Card";
import Card4 from "../Comp/Card";
import Card5 from "../Comp/Card";
import NewCollection from "../Comp/HomePage/NewCollectionProduct";
import HomeSlider from "../Comp/HomePage/HomeSlider";
import CustomButton from "../Comp/Custom/CustomButton";
import axios from "axios";
import { useState,useEffect } from "react";

const Home = () => {
  const components = [Card1, Card2, Card3, Card4, Card5];
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    // Fetch data from the API endpoint
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/Public/getItemsPromotion`)
      .then(response => {
        // Assuming the API response is the array of items
        setData(response.data);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div>
      <div className="Nav">
        <NavBarMarketPlace />
      </div>
      <div className="HomeSlider">
        <HomeSlider  />
      </div>
      <div className="Recommend " style={{backgroundColor:"white"}}>
        <p className="recommend-p1"style={{display:"none"}}>explore awesome product</p>
        <p className="recommend-p2"style={{display:"none"}}> recommended for you </p>

        <div className="recommend-item" style={{display:"none"}}>
          {components.map((Card, index) => (
            <div className="Cards" key={index}>
              <Card />
            </div>
          ))}
        </div>


        <div className="Img-div" style={{display:"none"}}>
          <img src={Gradient} className="middle-img" alt="Gradient" />
          <p className="middle-img-text">
            Paths are made by walking, Keep walking. Get 50% Off today on all
            snickers
          </p>
          <CustomButton className={"middle-img-btn"} name={"Start Shopping"} />
        </div>
        <div className="Coll-div">
          <NewCollection />
        </div>

        <div className="btn-div2">
          <CustomButton
            className={"explore-btn"}
            name={"Explore More Offers"}
          />
        </div>

        <Footer />
      </div>
    </div>
  );
};
export default Home;
