import React, { Component, Suspense, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Product from "./Pages/ProductPage";
import Shipping from "./Pages/Shipping";
import ShoppingCart from "./Pages/ShoppingCart";
import Billing from "./Pages/Billing";
import Category from "./Pages/Category";
import RestPassword1 from "./Pages/RestPassword1";
import RestPassword2 from "./Pages/RestPassword2";
import ProductItem from './Comp/ProductList/ProductItem';
import cookie from "js-cookie";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import allActions from "./Action/allActions";
const VerificationPage = React.lazy(() => import("./Pages/VerificationPage"));

function App() {
  const token = cookie.get("token");
  const currentUser = useSelector((state) => state.currentUser);
  const dispatch = useDispatch();
  const [requestedUrl, setRequestedUrl] = useState("");
  useEffect(() => {
    console.log("token");
    console.log(token);
    const vaildateUser = async () => {
      // verify a token symmetric
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/auth/user-profile`)
        .then((res) => {
          dispatch(allActions.userActions.setUser(res.data));
        })
        .catch((err) => {
          axios.defaults.headers.common["Authorization"] = ``;
          localStorage.clear();
          cookie.remove("token");
          dispatch(allActions.userActions.logOut());
        });
    };
    if (token) {
      vaildateUser();
    } else {
      localStorage.clear();
    }
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!requestedUrl) {
      setRequestedUrl(window.location.hash.substring(1));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Product/:id" element={<Product />} />
          <Route path="/Shipping" element={<Shipping />} />
          <Route path="/ShoppingCart" element={<ShoppingCart />} />
          <Route path="/Billing" element={<Billing />} />
          <Route path="/Category" element={<Category />} />
          <Route path="/ResetPassword1" element={<RestPassword1 />} />
          <Route path="/ResetPassword2" element={<RestPassword2 />} />
          <Route path="/verifyAccount/:token" element={<VerificationPage />} />
          <Route path="/category/:typeId" element={<ProductItem />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
