import React from 'react'
function Paginantion() {
  return (
    <div>
      
    </div>
  )
}

export default Paginantion
