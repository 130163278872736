import React, { useState, useEffect } from "react";
import product from "../../Layout/item1.png";

const OrderSummary = () => {
  const [orderItems, setOrderItems] = useState([]);

  useEffect(() => {
    const storedData = localStorage.getItem("cart");
    if (storedData) {
      setOrderItems(JSON.parse(storedData));
    }
  }, []);

  // Function to calculate the total price based on order items
  const calculateTotal = () => {
    let total = 0;
    orderItems.forEach((item) => {
      total += parseFloat(item.price) * parseFloat(item.quantity); // Update to calculate total price
    });
    return total.toFixed(2);
  };

  return (
    <>
      <div>
        <p className="OrderSummary-BigTitle">1. Your Order Summary</p>
      </div>
      <div className="OrderSummary-Background">
        <div className="OrderSummary-Title">{/* Title details here */}</div>
        {orderItems.map((item, index) => (
          <div key={index} className="OrderSummary-Product">
            <div className="OrderSummary-Product-detail1">
              <img
               src={item.images?.length > 0 ? `${process.env.REACT_APP_API_BASE_URL_FILE}/images/${item.images[0].image}` : 'default-image-source'}

                className="OrderSummary-Product-img"
                alt={item.name}
              />
              <div className="OrderSummary-Product-div">
                <p className="OrderSummary-Product-name">{item.name}</p>
                <p className="OrderSummary-Product-descrip">
                  {item.description}
                </p>
              </div>
            </div>
            <div className="OrderSummary-Product-detail2">
              <p className="OrderSummary-Product-detail-p">{item.color}</p>
              <p className="OrderSummary-Product-detail-p">{item.quantity}</p>
              <p className="OrderSummary-Product-detail-p">${(item.price * item.quantity).toFixed(2)}</p> {/* Calculate total price for individual item */}
              {/* Shipping and tax are not available in the provided data */}
              <p className="OrderSummary-Product-detail-p">$0.00</p> {/* Placeholder for shipping */}
              <p className="OrderSummary-Product-detail-p">$0.00</p> {/* Placeholder for tax */}
            </div>
          </div>
        ))}
        <div className="OrderSummary-Div">
          <div className="OrderSummary-Div1">
            <p>ADD A GIFT CARD OR PROMOTION CODE</p>
          </div>
          <div className="OrderSummary-Div2">
            <p className="OrderSummary-Div2-p">TOTAL</p>
            {/* Calculate total price dynamically */}
            <p className="OrderSummary-Div2-price">${calculateTotal()}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderSummary;
