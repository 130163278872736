import React from "react";
import Logo from "../Layout/walmart(white).svg";
import { NavLink } from "react-router-dom";
// Footer.js

const Footer2 = () => {
    return(
    <footer className="footer">
    <div className="footer-content">
      <div className="footer-left">
      Otloob
        <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea 
              commodo consequat.</p>
      </div>
      <div className="footer-right">
        <div className="column">
          
          <ul>
          <NavLink className="nav-link">
                        <li className="footer-link" >
                            Product
                        </li>
                        <li  className="footer-link">
                        Trending

                        </li>
                        <li  className="footer-link">
                        My Account

                        </li>
                        <li  className="footer-link">
                        Vendors

                        </li>
                        <li  className="footer-link">
                        Brands

                        </li>
                    </NavLink>
          </ul>
        </div>
        <div className="column">
         
          <ul>
          <NavLink className="nav-link">
                        <li className="footer-link">
                        Legals
                        </li>
                        <li className="footer-link">
                        License

                        </li>
                        <li className="footer-link">
                        Refund Policy

                        </li>
                        <li className="footer-link">
                        About Us

                        </li>
                        <li className="footer-link">
                        Contacts

                        </li>
                    </NavLink>
          </ul>
        </div>
        <div className="column">
         
          <ul>
            <li><p className="Link-p">Fell free get in touch with us via phone or send us a message </p></li>
            <li style={{marginBottom:"16%"}}>Phone: +123456789</li>
            <li>Email: example@example.com</li>
          </ul>
        </div>
      </div>
    </div>
  </footer>)
};

export default Footer2;
