import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios for making HTTP requests

import Tablets from "../../Layout/tablets.png";
import Basket from "../../Layout/basket.svg";
import Cart from "../../Layout/cart3.svg";
import Gradient from "../../Layout/Gradientimg.jpg";

import { useNavigate } from "react-router-dom";

const HomeSlider = () => {
  const [itemImage, setItemImage] = useState(null);
  const [items, setItems] = useState([]); // State to store fetched items
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch data from the API endpoint
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/Public/getItemsPromotion`)
      .then(response => {
        // Assuming the API response is an array of items
        const fetchedItems = response.data;
        setItems(fetchedItems); // Store fetched items in state
        if (fetchedItems.length > 0 && fetchedItems[0].number === 1) {
          setItemImage(`${process.env.REACT_APP_API_BASE_URL_FILE}/images/${fetchedItems[0].default_image}`); // Set the image URL
        } else {
          setItemImage(Tablets); // Default image if the number is not 1
        }
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []); // Empty dependency array means this effect runs once when the component mounts

  return (
    <div className="HomeSlider-resp">
      <div className="HomeSlider-Background-img">
        <img src={Gradient} alt="Gradient Background" className="HomeImg" />
      </div>
      <div style={{ width: "50%" }}>
        <img src={itemImage || Tablets} alt="Item" className="HomeTab" />
      </div>
      <div className="HomeSlider-Paragraph">
        <p className="Home-Text">Think different, think in colors.</p>
        <p className="Home-Text">The all new iPad lineup.</p>
      </div>

      <div style={{ display: "flex" }}>
        {items.length > 0 && (
          <button className="Home-BuyNow">
            <p className="Basket-P" onClick={() => navigate(`/Product/${items.id}`)}>Buy Now</p>
            <img src={Basket} alt="Basket" className="Basket" />
          </button>
        )}
      </div>
    </div>
  );
};

export default HomeSlider;
