import React, { useState } from "react";

const ShoppingProductCounter = ({ initialQuantity, onQuantityChange }) => {
  const [quantity, setQuantity] = useState(initialQuantity);

  const incNum = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    if (typeof onQuantityChange === "function") {
      onQuantityChange(newQuantity);
    }
  };

  const decNum = () => {
    const newQuantity = quantity - 1 >= 1 ? quantity - 1 : 1;
    setQuantity(newQuantity);
    if (typeof onQuantityChange === "function") {
      onQuantityChange(newQuantity);
    }
  };


  return (
    <div>
      <div className="ShoppingProduct-counter">
        <button className="ShoppingProduct-decbtn" onClick={decNum}>
          -
        </button>
        <input
          readOnly={true}
          type="number"
          className="ShoppingProduct-productinput"
          value={quantity}
        />
        <button className="ShoppingProduct-incbtn" onClick={incNum}>
          +
        </button>
      </div>
    </div>
  );
};

export default ShoppingProductCounter;
