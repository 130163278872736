import React, { useEffect, useState } from "react";
import axios from "axios";
import NavBarMarketPlace from "../Comp/NavBar";
import SideBar from "../Comp/ProductList/ProductListSideBar";
import Item from "../Comp/ProductList/Item";
import ProductItem from "../Comp/ProductList/ProductItemHeader";
import Pagination from "../Comp/ProductList/Paginantion";
import Footer from "../Comp/Footer";
import ReactPaginate from "react-paginate";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const ProductList = () => {
  const [data, setData] = useState([]);
  
  const [isLoading, setIsLoading] = useState(false);
  const [filterForm, setFilterForm] = useState({
    type_id: [],
    category_id: [],
    price: '',
  });
  const handlePage=(data)=>{
    console.log(data.selected);
      }
    
  const handleChangeFilter = (e) => {
    const { id, value, checked, type } = e.target;
    // console.log(id,value,checked,type); return
    if (type == 'checkbox') {
      setFilterForm({
        [id]: value == 1 ? true : false,
      });
    } else {
      setFilterForm({
        [id]: value,
      });
    }
  }
  const handleChangeMultiFilter = (id, value) => {
    setFilterForm(prevState => {
      const currentArray = prevState[id] || [];
      if (currentArray.includes(value)) {
        return {
          ...prevState,
          [id]: currentArray.filter(item => item !== value)
        };
      } else {
        return {
          ...prevState,
          [id]: [...currentArray, value]
        };
      }
    });
  };

  const getData = () => {
    console.log(filterForm);
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/Public/getItems`, {
        params: {
          type_id: filterForm.type_id,
          category_id: filterForm.category_id,
          price: filterForm.price,
        }
      })
      .then(function (response) {
        setData(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [filterForm]);

  return (
    <>
      <div className="Nav">
        <NavBarMarketPlace />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="acc-filter" >
              <SideBar
                filterForm={filterForm}
                handleChangeFilter={handleChangeFilter}
                handleChangeMultiFilter={handleChangeMultiFilter}
              />
            </div>
          </div>
          <div className="col-lg-9">
            <div style={{ marginTop: "15%", marginLeft: "-7%" }}>
              <ProductItem />
              <div className="item-grid">
                {
                  isLoading ? <CircularProgress />
                    :
                    data.map((item) => (
                      <div className="row-grid" key={item.id}>
                        <Item data={item} />                      </div>
                    ))
                }
              </div>
   

            </div>
          </div> 
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductList;