import React, { useState, useEffect } from "react";
import CustomButton from "../Custom/CustomButton";
import Star from "../../Layout/star.svg";
import StarFill from "../../Layout/star-fill.svg";
import Starhalf from "../../Layout/star-half.svg";
import Profile from "../../Layout/person-circle.svg";

// Define getStarImage outside of the Reviews component
const getStarImage = (rating, value) => {
  const baseStar = Star;
  const halfStar = Starhalf;
  const filledStar = StarFill;

  if (rating <= value) {
    return filledStar;
  } else if (rating - 0.5 <= value) {
    return halfStar;
  } else {
    return baseStar;
  }
};

const Reviews = () => {
  const [userRating, setUserRating] = useState(0);
  const [userReview, setUserReview] = useState("");
  const [reviews, setReviews] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({
    username: "JohnDoe", // Replace with actual username
    userId: "123", // Replace with actual user ID
  });

  const resetPopupState = () => {
    setUserRating(0);
    setUserReview("");
  };

  const handleRatingChange = (newRating) => {
    setUserRating(newRating);
  };

  const handleReviewChange = (event) => {
    setUserReview(event.target.value);
  };

  const calculateAverageRating = (reviews) => {
    if (reviews.length === 0) return 0;

    const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
    return totalRating / reviews.length;
  };

  const handleSubmitReview = () => {
    const newReview = {
      userId: userInfo.userId,
      username: userInfo.username,
      rating: userRating,
      review: userReview,
    };

    setReviews([...reviews, newReview]);

    // Save reviews to localStorage
    localStorage.setItem("reviews", JSON.stringify([...reviews, newReview]));

    resetPopupState(); // Reset state values
    setPopupOpen(false);
  };

  useEffect(() => {
    // Retrieve reviews from localStorage on component mount
    const storedReviews = localStorage.getItem("reviews");
    if (storedReviews) {
      setReviews(JSON.parse(storedReviews));
    }
  }, []); // Empty dependency array ensures the effect runs only on mount

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="Review-div">
              <div className="Review-div1">
                <p className="Review-title">
                  Reviews <span className="Review-nb">({reviews.length})</span>
                </p>
                <CustomButton
                  name={"Write Review"}
                  className={"Review-btn"}
                  onClick={() => setPopupOpen(true)}
                />
              </div>

              <div className="Review-div2">
                <img src={Star} className="Review-div2-star" alt="Star" />
                <div className="d-flex">
                  <p className="Review-div2-Rating">
                    {calculateAverageRating(reviews).toFixed(1)}
                  </p>
                  <p className="Review-div2-Rating2">/5</p>
                </div>
                <p className="Review-div2-p">Average Rating</p>
              </div>

              <div className="Review-div3">
                {isPopupOpen && (
                  <div className="Review-Popup">
                    <div className="Review-Popup-content">
                      <button
                        className="Review-Popup-close"
                        onClick={() => {
                          setPopupOpen(false);
                          resetPopupState(); // Reset state values
                        }}
                      >
                        X
                      </button>
                      <h2>Write a Review</h2>
                      <StarRatingInput
                        value={userRating}
                        onChange={handleRatingChange}
                        getStarImage={getStarImage} // Pass the function as a prop
                      />

                      <div className="Review-Comment">
                        <div className="Review-Comment-Title">
                          <img
                            src={Profile}
                            className="Review-Comment-profile"
                            alt="Profile"
                          />
                        </div>
                        <div className="Review-Comment-Comment">
                          <textarea
                            style={{ width: "97%", height: "150px" }}
                            placeholder="Write your review..."
                            value={userReview}
                            onChange={handleReviewChange}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginRight: "4%",
                        }}
                      >
                        <CustomButton
                          name={"Submit Review"}
                          className={"Review-btn"}
                          onClick={handleSubmitReview}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="ReviewsList">
                  <h2>Recent Reviews</h2>
                  {reviews.slice(0, 5).map((review, index) => (
                    <div key={index} className="ReviewItem">
                      <div className="ReviewItemRating">
                        {[1, 2, 3, 4, 5].map((rating) => (
                          <img
                            key={rating}
                            src={getStarImage(rating, review.rating)}
                            className="Review-Comment-Star"
                            alt={`Star ${rating}`}
                          />
                        ))}
                      </div>
                      <p className="ReviewItemText">
                        <strong>{review.username}</strong>: {review.review}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const StarRatingInput = ({ value, onChange, getStarImage }) => {
  const handleClick = (clickedValue) => {
    onChange(clickedValue);
  };

  return (
    <div className="StarRatingInput">
      <p>Your Rating:</p>
      <div style={{ textAlign: "initial" }}>
        {[1, 2, 3, 4, 5].map((rating) => (
          <img
            key={rating}
            src={getStarImage(rating, value)}
            className="Review-Comment-Star"
            alt={`Star ${rating}`}
            onClick={() => handleClick(rating)}
          />
        ))}
      </div>
    </div>
  );
};

export default Reviews;
