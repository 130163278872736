import React from "react";
import NavBarMarketPlace from "../Comp/NavBar";
import Footer from "../Comp/Footer";
import Product from "../Comp/ProductDetail/ProductDetail";
import Review from "../Comp/ProductDetail/Reviews";
import Card1 from "../Comp/Card";
import Card2 from "../Comp/Card";
import Card3 from "../Comp/Card";
import Card4 from "../Comp/Card";
import Card5 from "../Comp/Card";
import { useNavigate } from "react-router-dom";

const ProductPage = () => {
  const components = [Card1, Card2, Card3, Card4, Card5];
  const navigate = useNavigate();

  return (
    <>
      <div className="Nav">
        <NavBarMarketPlace />
      </div>

      <Product />
      <div><Review /></div>


      <div className="container">
        <div className="row">
          <div
            style={{
              marginTop: "5%",
              marginBottom: "5%",
              borderBottom: "1px solid #cccccc",
              display:"none",
            }}
          >
            <p className="Confirmation-Title2">YOU MAY ALSO LIKE</p>
            <div className="Confirmation-Previous">
              <div className="recommend-item">
                {components.map((Card, index) => (
                  <div className="Cards" key={index}>
                    <Card />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div
            style={{
              marginTop: "2%",
              marginBottom: "5%",
              display:"none",
            }}
          >
            <p className="Confirmation-Title2">PREVIOUSLY VIEWED</p>
            <div className="Confirmation-Previous">
              <div className="recommend-item">
                {components.map((Card, index) => (
                  <div className="Cards" key={index}>
                    <Card />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "5%" }}>
        <Footer />
      </div>
    </>
  );
};

export default ProductPage;
