import React, { useEffect, useState } from "react";
import Counter from "../Comp/PopUpCount";
import trash from "../Layout/trash3-fill.svg";
import ProductImage from "../Layout/item1.png";
import CustomButton from "./Custom/CustomButton";

const WhishList = () => {
  const [localStorageData, setLocalStorageData] = useState(null);

  useEffect(() => {
    // Retrieve data from local storage on component mount
    const storedData = localStorage.getItem("Wishcart");
    if (storedData) {
      setLocalStorageData(JSON.parse(storedData));
    }
  }, []);

  const handleDeleteItem = (index) => {
    // Copy the current localStorageData array
    const updatedData = [...localStorageData];

    // Remove the item at the specified index
    updatedData.splice(index, 1);

    // Update state with the modified array
    setLocalStorageData(updatedData);

    // Update localStorage with the modified array
    localStorage.setItem("Wishcart", JSON.stringify(updatedData));
  };

  return (
    <div>
      {localStorageData && localStorageData.length > 0 ? (
        localStorageData.map((item, index) => (
          <div key={index} className="Item">
            <img src={ProductImage} className="popup-item" />
            <div className="item-text">
              <p className="item-title">{item.name}</p>
              <p className="item-description">{item.description}</p>
              <div className="div10">
                <CustomButton className={'whish-btn'} name={"ADD TO CART"} />
                <Counter />
                <img
                  className="trash-img"
                  src={trash}
                  alt="Trash Icon"
                  onClick={() => handleDeleteItem(index)}
                />
              </div>
            </div>
          </div>
        ))
      ) : (
        <p style={{ display: 'flex', justifyContent: 'center', color: 'black' }}>
          No product data available.
        </p>
      )}
    </div>
  );
};

export default WhishList;
