import React from "react";
import ReactDOM from "react-dom/client";
import "../src/Style/Home.css";
import "../src/Style/Footer.css";
import "../src/Style/BasketPopup.css";
import "../src/Style/PopupItem.css";
import "../src/Style/Card.css";
import "../src/Style/NewCollectionProduct.css";
import "../src/Style/ProductDetail.css";
import "../src/Style/Shipping.css";
import "../src/Style/PopUpCounter.css";
import "../src/Style/ShoppingCart.css";
import "../src/Style/ProcessLine.css";
import "../src/Style/Login.css";
import "../src/Style/SignIn.css";
import "../src/Style/Register.css";
import "../src/Style/Billing.css";
import "../src/Style/Confirmation.css";
import "../src/Style/OrderSummary.css";
import '../src/Style/NavBar.css';
import '../src/Style/ShoppingProduct.css';
import '../src/Style/ReviewOrderAddress.css';
import '../src/Style/DeliveryMethod.css';
import '../src/Style/PaymentDetail.css';
import '../src/Style/OrderConfirmation.css';
import '../src/Style/Accordion.css';
import '../src/Style/ProductReview.css';
import '../src/Style/CategorySideBar.css';
import '../src/Style/SearchAccordion.css';
import '../src/Style/CategoryProductItem.css';
import '../src/Style/ResetPassword.css';




import { Provider } from 'react-redux'
import store from './Store'




import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
