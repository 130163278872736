import React, { useEffect, useState } from "react";
import QuantityCounter from "./PopUpCount";
import trash from "../Layout/trash3-fill.svg"; // Check this path

const PopupItem = ({ onUpdateTotal }) => {
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    const storedData = localStorage.getItem("cart");
    if (storedData) {
      setCartItems(JSON.parse(storedData));
    }
  }, []);

  const updateLocalStorageAndState = (updatedData) => {
    localStorage.setItem("cart", JSON.stringify(updatedData));
    setCartItems(updatedData);
    onUpdateTotal(updatedData);
  };

  const handleQuantityChange = (index, newQuantity) => {
    const updatedItems = cartItems.map((item, i) =>
      i === index
        ? { ...item, quantity: newQuantity, totalPrice: item.price * newQuantity }
        : item
    );
    updateLocalStorageAndState(updatedItems);
  };

  const handleDeleteItem = (index) => {
    console.log("Deleting item at index:", index); // Check if this log appears
    const updatedItems = cartItems.filter((_, i) => i !== index);
    updateLocalStorageAndState(updatedItems);
  };

  return (
    <>
      {cartItems && cartItems.length > 0 ? (
        <div>
          {cartItems.map((product, index) => (
            <div key={index} className="Item" style={{ display: "flex", marginBottom: "3%" }}>
              <img
                src={product.images?.length > 0 ? `${process.env.REACT_APP_API_BASE_URL_FILE}/images/${product.images[0].image}` : 'default-image-source'}
                className="popup-item"
                alt={`Product ${index}`}
              />
              <div className="item-text">
                <p className="item-title">{product.name}</p>
                <p className="item-description">{product.description}</p>
                <div className="div10">
                  <QuantityCounter
                    initialQuantity={product.quantity || 1}
                    onQuantityChange={(newQuantity) => handleQuantityChange(index, newQuantity)}
                  />
                  <p className="item-price">{`$${(product.totalPrice || 0)}`}</p>
                  <img
                    className="trash-img"
                    src={trash}
                    alt="Trash Icon"
                    onClick={() => handleDeleteItem(index)}
                    style={{ cursor: "pointer" }} // Ensure cursor style is applied
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p style={{ display: "flex", justifyContent: "center", color: "black" }}>
          No product data available.
        </p>
      )}
    </>
  );
};

export default PopupItem;
